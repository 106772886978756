import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classNames from 'classnames';

import './LinksBlock.scss';


const LinksBlock = ({ className }) => {
  const LinksBlockClassNames = classNames(
    'links-block',
    className,
  );

  return (
    <div className={LinksBlockClassNames}>
      <h3 className="links-block__heading">
        Read also:
      </h3>
      <ul className="links-block__list">
        <li className="links-block__item">
          <Link
            to="/money-back-guarantee.html"
            className="links-block__item__link"
            activeClassName="links-block__item__link_active"
            rel="nofollow"
          >
            Money Back Guarantee
          </Link>
        </li>
        <li className="links-block__item">
          <Link
            to="/revisions-policy.html"
            className="links-block__item__link"
            activeClassName="links-block__item__link_active"
            rel="nofollow"
          >
            Revision Policy
          </Link>
        </li>
        <li className="links-block__item">
          <Link
            to="/privacy-policy.html"
            className="links-block__item__link"
            activeClassName="links-block__item__link_active"
            rel="nofollow"
          >
            Privacy Policy
          </Link>
        </li>
        <li className="links-block__item">
          <Link
            to="/cookie-policy.html"
            className="links-block__item__link"
            activeClassName="links-block__item__link_active"
            rel="nofollow"
          >
            Cookie Policy
          </Link>
        </li>
        <li className="links-block__item">
          <Link
            to="/plagiarism-free-guarantee.html"
            className="links-block__item__link"
            activeClassName="links-block__item__link_active"
            rel="nofollow"
          >
            Plagiarism-Free Guarantee
          </Link>
        </li>
        <li className="links-block__item">
          <Link
            to="/terms-conditions.html"
            className="links-block__item__link"
            activeClassName="links-block__item__link_active"
            rel="nofollow"
          >
            Terms & Conditions
          </Link>
        </li>
        <li className="links-block__item">
          <Link
            to="/social-responsibility-notice.html"
            className="links-block__item__link"
            activeClassName="links-block__item__link_active"
            rel="nofollow"
          >
            Director&#39;s Notice on Social Responsibility
          </Link>
        </li>
      </ul>
    </div>
  );
};

LinksBlock.propTypes = {
  className: PropTypes.string,
};

LinksBlock.defaultProps = {
  className: undefined,
};

export default LinksBlock;
