import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


class AccordionItem extends Component {
  state = {
    isOpen: false,
  };

  handleAccordionItemClick = () => {
    const { isOpen } = this.state;

    if (isOpen) {
      this.setState({
        isOpen: false,
      });
    } else {
      this.setState({
        isOpen: true,
        itemHeight: this.itemRef.clientHeight,
      });
    }
  };

  handleAccordionItemKeyPress = (e) => {
    if (e.charCode === 13 || e.charCode === 32) {
      e.preventDefault();
      this.handleAccordionItemClick();
    }
  };


  render() {
    const { isOpen, itemHeight } = this.state;
    const { title, children, className } = this.props;
    const currentHeight = isOpen ? itemHeight : 0;

    return (
      <div
        className={classNames(
          'Accordion__item',
          !isOpen && 'Accordion__item_close',
          isOpen && 'Accordion__item_open',
          className,
        )}
      >
        <div
          onClick={this.handleAccordionItemClick}
          onKeyPress={this.handleAccordionItemKeyPress}
          className="Accordion__item__heading"
          aria-expanded={isOpen}
          role="button"
          tabIndex={0}
        >
          <h4>
            {title}
          </h4>
        </div>
        <div
          className="Accordion__item__body"
          style={{ height: `${currentHeight}px` }}
          aria-hidden={!isOpen}
        >
          <div
            className="Accordion__item__content"
            ref={(el) => { this.itemRef = el; }}
          >
            {children}
          </div>
        </div>
      </div>
    );
  }
}

AccordionItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

AccordionItem.defaultProps = {
  className: undefined,
};

export default AccordionItem;
