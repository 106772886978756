// @todo enable next eslint rules
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import Calculator from '../../components/Calculator';
import { Accordion, AccordionItem } from '../../components/Accordion';
import VerticalTextBlock from '../../components/VerticalTextBlock';
import {
  CommonPageSection,
  Content,
  ContentItemWrap,
  ContentItem,
  Sidebar,
  LinksBlock,
} from '../../components/CommonPageSection';
import CTA from '../../components/CTA';

import './index.scss';


/**
 * @todo escape special characters in jsx
 */
const MoneyBackGuaranteePage = () => (
  <Layout
    pageWrapperClassName="moneyback-page"
    headerClassName="header_white-bg"
  >

    <Helmet
      title="Money Back Guarantee | TakeAwayEssay.com"
      meta={[
        { name: 'description', content: '' },

        { name: 'robots', content: 'noindex,nofollow' },

        { property: 'og:title', content: 'Money Back Guarantee | TakeAwayEssay.com' },
        { property: 'og:description', content: '' },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
      ]}
      link={[
        { rel: 'canonical', href: 'https://takeawayessay.com/money-back-guarantee.html' },
      ]}
    />

    <CommonPageSection>
      <Content>
        <ContentItemWrap>
          <VerticalTextBlock
            sectionClassName="common-page"
            dotsStyle="style-4"
            text="Money Back"
          />

          <ContentItem>
            <h1>
              Money Back Guarantee
            </h1>
            <p>
              BEWARE! Most companies in the academic writing industry DO NOT offer a
              specific Money Back Guarantee. They often tend not to keep their promise to return
              your money if you
              are not satisfied with the order. At TakeAwayEssay.com,
              {' '}
              <strong>
                we want you to FEEL SAFE when
                placing your order!
              </strong>
            </p>
            <p>
              Our Money Back Guarantee gives you the right to request and receive a refund at any
              stage of your
              order, if anything goes wrong. This includes any possible problems you might
              encounter, and secures
              your interests. Our company enjoys a high customer satisfaction rate, while refund
              requests are
              rare, which is partly due to our crystal-clear Money Back Guarantee. Quality-based
              refund requests
              are reviewed by our specialists and processed within a 14 day period, all other refund
              requests are
              processed within 3-4 business days.
            </p>

            <Accordion>
              <AccordionItem title="I want to cancel my order">
                <p>
                  If you decide to cancel your order before we assign a writer to work on it, you
                  receive a
                  100% refund. If a writer has already been assigned, and it is confirmed by
                  order`s status bar
                  at the top of your order page, the refund amount depends on your deadline. You
                  can claim a 70%
                  refund if half or less of the period has passed, and up to 50% thereafter. This
                  is because the
                  writer who starts on your assignment should receive the compensation for the
                  work done.
                </p>
                <p>
                  If you want to cancel the order, please make sure to contact our Support Team
                  via phone or
                  Live Chat as soon as possible, and then confirm the cancellation with a message
                  on the
                  respective order page. This is the only way we can process your request shortly.
                </p>
                <p>
                  Please note that you cannot receive a refund for order cancellation if the paper
                  has been completed and delivered to your order page on time. If the paper has
                  been delivered within the deadline you stipulate and pay for, you can only
                  request a refund due to quality concerns, through the &#34;Refund&#34; button on
                  your order page.
                </p>
                <p>
                  Provided you haven`t downloaded the paper and the circumstances for order
                  cancellation were
                  beyond your control a partial refund up to 30% might still be considered.
                </p>
              </AccordionItem>
              <AccordionItem title="I paid for one order twice">
                <p>
                  If you notice that you were charged twice, and you receive two receipts from the
                  payment processing system, do not hesitate to contact us. Forward both receipts to
                  our email and the extra payment will be refunded in full within the shortest
                  possible time.
                </p>
              </AccordionItem>
              <AccordionItem title="I accidentally placed two identical orders">
                <p>
                  If you complete the payment for two identical orders by mistake, please contact
                  us
                  immediately to clarify the situation before we proceed with both orders. It is
                  your
                  responsibility to cancel any orders you do not need, before we assign a writer
                  to work
                  on&nbsp;them. Otherwise, they are regarded as regular orders and standard refund
                  procedures
                  apply (see *I want to cancel my order*).
                </p>
              </AccordionItem>
              <AccordionItem title="No writer was found to work on my order">
                <p>
                  We always do our best to assign&nbsp;the most appropriate&nbsp;writer to work on
                  your paper;
                  however, it is still possible&nbsp;that on rare occasions the writer is not
                  found. If this
                  does occur, we issue a full refund for you.
                </p>
                <p>
                  If you request for the revision of the finished paper to be completed by a
                  different writer,
                  and a suitable one is not found,&nbsp;our Dispute Resolution Specialist reviews
                  your claim and
                  proceeds with an appropriate refund after you request it.
                </p>
              </AccordionItem>
              <AccordionItem title="My paper was late">
                <p>
                  If lateness occurs due to the late upload of extra materials on your part, you
                  cannot apply
                  for a lateness refund.&nbsp;Please make sure all paper details and additional
                  materials are
                  provided upon placing the order.
                </p>
                <p>
                  If due to some other reason the first version of a paper is delivered after the
                  original
                  deadline, you can request recalculation of the order price, according to the
                  deadline for
                  delivery.&nbsp;For example, an 8-hour delivery paper completed an hour late now
                  falls&nbsp;into the 24-hour delivery category, and should cost less. The
                  difference in price
                  is refunded.&nbsp;If&nbsp;lateness occurs with a long-term order (11-14 days or
                  longer) we
                  refund 7% upon your request.
                </p>
                <p>
                  If the first version of a paper is late and is not approved by you, you are
                  eligible for a
                  full refund as you might not need the paper anymore.
                </p>
                <p>
                  All lateness refund requests should be submitted in a note on your personal
                  order page,
                  within 14 days from the time the paper was delivered.
                </p>
              </AccordionItem>
              <AccordionItem title="The revision of my paper was late">
                <p>
                  Delay refunds and price recalculation do not apply to revisions since revision
                  deadlines are set individually for each order.
                </p>
                <p>
                  Make sure your writer has a sufficient amount of time to complete your order. For
                  example, if your paper is due in 7 days, choose a 5-day deadline so we have time
                  for any possible revisions.
                </p>
              </AccordionItem>
              <AccordionItem
                title="I am not fully satisfied with the quality of the paper. However, I accept it and will revise it myself. I request a partial refund."
              >
                <p>
                  If you are not completely satisfied with the quality of the paper you receive,
                  you may
                  request a free revision, or ask for a different writer to be assigned to your
                  order.
                  Alternatively,
                  {' '}
                  <strong>
                    you can request a partial refund by clicking the "Refund"
                    button
                  </strong>
                  {' '}
                  on your personal order page and choosing
                  {' '}
                  <em>
                    "I am not fully satisfied with
                    the quality of the paper. However, I accept it and will revise it myself. I
                    request a
                    partial refund"
                  </em>
                  .
                </p>
                <p>
                  If you choose this option, you are automatically provided with the MS Word
                  version of the
                  paper. You then have
                  {' '}
                  <strong>
                    14 days to provide strong reasons and examples
                  </strong>
                  {' '}
                  to back
                  up your claim, or your own revised version of our paper as supporting evidence.
                  As soon as you
                  do that,&nbsp;
                  <strong>
                    up to 40% could be refunded
                  </strong>
                  {' '}
                  at the Dispute Department’s
                  discretion. To request a partial refund after you have already approved the last
                  version of
                  your order, contact our support team in chat, over the phone, or through your
                  personal order
                  page for assistance.
                </p>
                <p>
                  Failure to provide information required for dispute resolution
                  {' '}
                  <strong>
                    within 14
                    days
                  </strong>
                  {' '}
                  results in cancellation of the dispute, after which no refund is possible.
                </p>
                <p>
                  All partial refund requests should be submitted&nbsp;using the&nbsp;"Refund"
                  button on your
                  personal order page
                  {' '}
                  <strong>
                    within 14 days from the time the paper is approved.
                  </strong>
                </p>
              </AccordionItem>
              <AccordionItem
                title="I am completely dissatisfied with the quality of the paper, so I have
                      decided to write it myself. I request a full refund."
              >
                <p>
                  If you are completely dissatisfied with the quality of the paper you receive,
                  you may request
                  a
                  {' '}
                  <strong>
                    free revision
                  </strong>
                  , or ask for a different writer to be assigned to your order.
                  Alternatively,
                  {' '}
                  <strong>
                    if you haven’t previously approved the paper, you can request
                    a&nbsp;full refund by clicking the "Refund"
                  </strong>
                  {' '}
                  button on your personal order page, and
                  choosing&nbsp;
                  <em>
                    "I am completely dissatisfied with the quality of the paper, so I have
                    decided to write it myself. I request a full refund"
                  </em>
                  .
                </p>
                <p>
                  Please note that if you choose this option, the paper we created is immediately
                  posted online
                  for commercial purposes. You then have
                  {' '}
                  <strong>
                    14 days to provide strong reasons and
                    examples
                  </strong>
                  {' '}
                  to back up your claim. You could also upload a copy of your own version of
                  the paper as supporting evidence that our writer’s work did not meet any of your
                  expectations.
                  The paper you provide is discarded immediately after our review, and is not
                  stored in our
                  internal database or shared with any third party. As soon as you do that,&nbsp;
                  <strong>
                    up to
                    100% could be refunded
                  </strong>
                  {' '}
                  at the Dispute Department’s discretion.
                </p>
                <p>
                  Failure to provide information required for the dispute resolution
                  {' '}
                  <strong>
                    within 14
                    days
                  </strong>
                  {' '}
                  results in cancellation of the dispute, after which no refund is possible.
                </p>
                <p>
                  In case of a full refund request, the Company reserves the right to publish the
                  paper online
                  for commercial purposes. We do not keep any kind of essay databases, and
                  "publish online"
                  means that if the paper is googled, searched for in a search engine, or checked
                  by any kind of
                  anti-plagiarism software, it links back to our website. It may also be published
                  as content or
                  as a sample essay. This is done to protect our writer’s work in cases when a
                  customer claims a
                  refund after the work has been completed and used.
                </p>
                <p>
                  Customers have the opportunity to review their order and request additional
                  corrections if
                  necessary.
                  {' '}
                  <strong className="red">
                    However, once you press the "Approve order" button, which
                    provides access to an editable and printable version of the paper, you cannot
                    request a full
                    refund. If you have at least once approved either the initial, or the revised
                    version of
                    your paper using the "Approve Order" button, you are only eligible for a
                    partial
                    refund.
                  </strong>
                </p>
                <p>
                  All full&nbsp;refund requests should be submitted through the&nbsp;
                  <strong>
                    "Refund"
                  </strong>
                  {' '}
                  button on your personal order page
                  {' '}
                  <strong>
                    within 14 days from the
                    time the paper is delivered
                  </strong>
                  .
                </p>
              </AccordionItem>
              <AccordionItem
                title="I would like to receive a refund for an additional
                      service/feature"
              >
                <p>
                  <strong>
                    Progressive Delivery order
                  </strong>
                  <br />
                  For orders using the Progressive Delivery feature, the deadline for a revision
                  request is
                  limited to 21 days after initial approval. Once you press the "Approve part"
                  button in your
                  Progressive Delivery and proceed to the next section of your order, you cannot
                  request a
                  refund for the previously approved part.
                </p>
                <p>
                  You can receive a refund for only the last section of the order delivered to
                  you. All parts
                  of the order approved using "Approve part" button become non-refundable once we
                  start working
                  on the next section.&nbsp; The 10% Progressive Delivery fee itself is
                  non-refundable.
                </p>
                <p>
                  <strong>
                    Samples service
                  </strong>
                  <br />
                  The $5 deposit for our Writer’s sample service is non-refundable, so please make
                  sure you
                  check the samples provided on time. If you fail to request a different writer
                  within the given
                  time, we regard it as acceptance of the writer initially assigned.
                </p>
                <p>
                  <strong>
                    Writer’s category
                  </strong>
                  <br />
                  {' '}
                  If you pay the extra fee for an Advanced or Top writer, but we are unable to
                  find one to work on your order at the time, and assign a writer with a lower
                  category (such as Standard) instead, the extra fee is refunded to you.
                </p>
                <p>
                  <strong>
                    A copy of the sources used
                  </strong>
                  <br />
                  The additional fee you pay for this service
                  becomes non-refundable once the copies of the sources are delivered with your
                  paper. In case
                  they were not provided upon delivery for some reason this payment will be
                  refunded.
                </p>
              </AccordionItem>
              <AccordionItem title="I received my completed paper, but forgot to approve it">
                <p>
                  Please note you have 14 days to approve your order (or order part for orders
                  with Progressive
                  Delivery feature). Time for approval is calculated automatically from the moment
                  the last
                  version is uploaded to your personal order page. If you fail to request a
                  revision or to
                  approve your order (or order part)
                  {' '}
                  <strong>
                    within 14 days, the paper is approved automatically
                    by the system
                  </strong>
                  . From the moment the order is approved (by you or the system), you
                  have 14 days to request a refund. After that, no refund request is possible.
                </p>
              </AccordionItem>
            </Accordion>

          </ContentItem>
        </ContentItemWrap>
      </Content>
      <Sidebar>
        <LinksBlock />
      </Sidebar>
    </CommonPageSection>

    <Calculator />

    <CTA isOnlyWaves />

  </Layout>
);

export default MoneyBackGuaranteePage;
