import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classNames from 'classnames';

import './index.scss';


const CTA = ({
  isOnlyWaves,
  children,
  btnText,
}) => (
  <section
    className={classNames(
      'cta-section',
      isOnlyWaves && 'cta-section_only-waves',
      !isOnlyWaves && 'cta-section_text-with-waves',
    )}
  >

    {!isOnlyWaves && (
      <div className="cta-section__container container">
        <h2 className="cta-section__heading">
          {children}
        </h2>
        <Link
          className="cta-section__btn btn btn_normal btn_with-blue-bg btn_blue"
          to="/dashboard/orders/create/"
          rel="nofollow"
        >
          {btnText}
        </Link>
      </div>
    )}

    <div
      data-observed-bg
      className="cta-section__waves"
    />

  </section>
);

CTA.propTypes = {
  isOnlyWaves: PropTypes.bool,
  children: PropTypes.node,
  btnText: PropTypes.string,
};

CTA.defaultProps = {
  isOnlyWaves: false,
  children: undefined,
  btnText: 'Order your paper',
};

export default CTA;
