import React from 'react';
import PropTypes from 'prop-types';


const Content = ({ children }) => (
  <div className="common-page-section__content">
    {children}
  </div>
);

Content.propTypes = {
  children: PropTypes.node,
};

Content.defaultProps = {
  children: undefined,
};

export default Content;
