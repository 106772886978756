/* globals Calc */
// @todo enable next eslint rules
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-new */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isBrowser from '../../utils/helpers/isBrowser';
import Spinner from '../Spinner';
import VerticalTextBlock from '../VerticalTextBlock';

import './index.scss';
import './calculator.scss';

// eslint-disable-next-line global-require
const utils = typeof window !== 'undefined' ? require('@uvocore-front/utils') : null;

class Calculator extends Component {
  state = {
    calcLoaded: false,
  };

  componentDidMount() {
    if (isBrowser && this.calcRef) {
      utils.observeElement({
        element: '[data-calc-mini]',
        marginValue: 300,
        callback: () => {
          utils.getWidget({
            widgetLink: '/assets/js/calc.js',
            fn: () => {
              this.getCalc();
            },
          });
        },
      });
    }
  }

  getCalc = () => {
    const {
      academicLevel,
      paperType,
      pages = 1,
    } = this.props;

    new Calc({
      ID: '169',
      calculatorType: 'DefaultCalculator',
      format: 'json',
      callback: (i) => {
        this.setState({
          calcLoaded: !i.isLoading,
        });
      },
      options: {
        deafault: {
          deadlinesDefault: [101599, 101683, 101731],
          academicLevelDefault: academicLevel,
          paperTypesDefault: paperType,
          pagesDefault: pages,
        },
      },
    });
  };

  render() {
    const {
      calcLoaded,
    } = this.state;

    return (
      <section className="calculator-section">
        <div className="calculator-section__container container">
          <VerticalTextBlock
            sectionClassName="calculator"
            text="Calculator"
            dotsStyle="style-2"
          />

          <div className="calculator-section__wrap">
            <div className="calculator-section__calculator">
              <span className="calculator-section__calculator__shape" />

              <div className="calculator">
                <article className="calculator__wrap">
                  <h2 className="calculator__title">
                    Calculate the price of your paper
                  </h2>
                  <div
                    ref={(el) => {
                      this.calcRef = el;
                    }}
                    className="calculator__box"
                    data-calc-mini
                  >
                    <form
                      name="uvocalculator"
                      className={classNames(
                        'calculator__form',
                        !calcLoaded && 'calculator__form_loading',
                        calcLoaded && 'calculator__form_loaded',
                      )}
                      method="get"
                      action="/"
                      data-calculator-form
                    >

                      <div className="calculator__form-lft">
                        <label htmlFor="paperTypeId">
                          Type of paper needed
                        </label>
                        <div className="styled-select">
                          <select
                            className="f_sz_400 validate[required]"
                            name="paperTypeId"
                            id="paperTypeId"
                            data-academic-level-select
                          />
                        </div>
                      </div>

                      <div className="calculator__form-rgt">
                        <label htmlFor="pages-input">
                          Pages
                        </label>
                        <div className="in-bl">
                          <div className="contentspin clearfix">
                            <button
                              type="button"
                              className="dec buttonspin"
                              title="Decrease"
                              data-minus-button
                            >
                              −
                            </button>
                            <input
                              type="text"
                              className="ui-spinner"
                              name="pages"
                              id="pages-input"
                              data-pages-input
                            />
                            <button
                              type="button"
                              className="inc buttonspin"
                              title="Increase"
                              data-plus-button
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <span className="pages_qty" data-words-qty />
                      </div>

                      <div
                        className="field_tip field_tip_discount"
                        style={{ display: 'none' }}
                        data-tip-discount
                      >
                        You will get a personal manager and a discount.
                      </div>

                      <div className="field_tip field_tip_paper_type_id" data-notice />

                      <div className="calculator__acdemic-levels">
                        <label>
                          Academic level
                        </label>
                        <div
                          className="acdemic-levels ui-buttonset visible-in-desktop"
                          data-academic-level-control
                        />
                        <div className="visible-in-mobile styled-select">
                          <select data-academic-level-select-mobile aria-label="Academic level" />
                        </div>
                      </div>

                      <div className="calculator__form-clr">
                        <div className="tip_academic_level">
                          <div className="level-content">
                            <label>
                              Deadline
                            </label>
                            <div className="dynamicPart">
                              <div className="dynamicPart-wrap" data-tariff-control />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="calculator__total_price">
                        <strong className="calculator__total_price__title">
                          Total price:
                        </strong>
                        <span className="calculator__total_price__value" data-total-price>
                          $26
                        </span>
                      </div>

                      <div className="calculator__submit_block">
                        <button
                          type="submit"
                          className="calculator__submit_block__btn btn btn_normal btn_with-blue-bg btn_blue"
                          title="Continue to Order"
                          data-submit-button
                        >
                          <em className="forIE">
                            Continue to order
                          </em>
                        </button>
                      </div>

                    </form>

                    {!calcLoaded && <Spinner />}
                  </div>
                </article>
              </div>
            </div>

            <div className="calculator-section__sidebar">
              <div className="calculator-section__sidebar__block">
                <h3>
                  Free features
                </h3>
                <ul className="dot-list">
                  <li>
                    Formatting (APA, MLA, Harvard, Chicago/Turabian)
                  </li>
                  <li>
                    Bibliography
                  </li>
                  <li>
                    Title page
                  </li>
                  <li>
                    Upload custom grading criteria
                  </li>
                </ul>
              </div>
              <div className="calculator-section__sidebar__block">
                <h3>
                  Additional services
                </h3>
                <ul className="dot-list">
                  <li>
                    Part-by-part payment
                  </li>
                  <li>
                    Links to used sources
                  </li>
                  <li>
                    Review your writer’s samples
                  </li>
                  <li>
                    Charts and PowerPoint slides
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </section>
    );
  }
}

Calculator.propTypes = {
  academicLevel: PropTypes.number,
  paperType: PropTypes.number,
  pages: PropTypes.number,
};

Calculator.defaultProps = {
  academicLevel: 3,
  paperType: 2,
  pages: 1,
};

export default Calculator;
