import React from 'react';
import PropTypes from 'prop-types';

import './CommonPageSection.scss';


const CommonPageSection = ({ children }) => (
  <section className="common-page-section">
    <div className="common-page-section__container container">
      {children}
    </div>
  </section>
);

CommonPageSection.propTypes = {
  children: PropTypes.node,
};

CommonPageSection.defaultProps = {
  children: undefined,
};

export default CommonPageSection;
