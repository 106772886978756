import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const ContentItem = ({ children, className }) => {
  const contentItemClassNames = classNames(
    'common-page-section__content-item',
    className,
  );

  return (
    <div className={contentItemClassNames}>
      {children}
    </div>
  );
};

ContentItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

ContentItem.defaultProps = {
  children: undefined,
  className: undefined,
};

export default ContentItem;
