import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.scss';


const VerticalTextBlock = ({
  sectionClassName,
  text,
  dotsStyle,
}) => {
  const verticalTextClass = classNames(
    `${sectionClassName}-section__vertical-text`,
    'vertical-text',
    `dots-${dotsStyle}`,
    'font-rift',
    'font-rift_inverse',
  );

  return (
    <div className={verticalTextClass}>
      <div className="vertical-text__content">
        {text}
      </div>
    </div>
  );
};

VerticalTextBlock.propTypes = {
  sectionClassName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  dotsStyle: PropTypes.string,
};

VerticalTextBlock.defaultProps = {
  dotsStyle: 'hidden',
};

export default VerticalTextBlock;
