import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Accordion.scss';


const Accordion = ({ children, className }) => (
  <div
    className={classNames(
      'Accordion',
      className,
    )}
  >
    {children}
  </div>
);

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Accordion.defaultProps = {
  className: undefined,
};

export default Accordion;
