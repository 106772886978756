import React from 'react';
import PropTypes from 'prop-types';


const Sidebar = ({ children }) => (
  <div className="common-page-section__sidebar">
    {children}
    <div className="common-page-section__circle-wrap">
      <span className="common-page-section__circle common-page-section__circle_four" />
      <span className="common-page-section__circle common-page-section__circle_three" />
      <span className="common-page-section__circle common-page-section__circle_one" />
    </div>
  </div>
);

Sidebar.propTypes = {
  children: PropTypes.node,
};

Sidebar.defaultProps = {
  children: undefined,
};

export default Sidebar;
