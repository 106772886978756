import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.scss';

const Spinner = ({ className }) => (
  <div
    className={classNames(
      'Spinner',
      `Spinner--${className}`,
    )}
  >
    <div className="Spinner__inner" />
  </div>
);

Spinner.propTypes = {
  className: PropTypes.string,
};

Spinner.defaultProps = {
  className: 'center',
};

export default Spinner;
