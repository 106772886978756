import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const ContentItemWrap = ({ children, className }) => {
  const contentItemWrapClassNames = classNames(
    'common-page-section__content-item-wrap',
    className,
  );

  return (
    <div className={contentItemWrapClassNames}>
      {children}
    </div>
  );
};

ContentItemWrap.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

ContentItemWrap.defaultProps = {
  children: undefined,
  className: undefined,
};

export default ContentItemWrap;
